












import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component({})
export default class RewordPop extends Vue {
  async created() {
    document.body.style.overflow = "hidden";
  }
  destroyed() {
    document.body.style.overflow = "";
  }
  @Emit()
  confirm(){
    
  }
}
