

















import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component({})
export default class RulePop extends Vue {
  @Prop() htmlWord!: any;
  async created() {
    document.body.style.overflow = "hidden";
  }
  destroyed() {
    document.body.style.overflow = "";
  }
  @Emit()
  close() {
      return
  }
}
