


























































































import SalesSnapCardList from "@/views/special-sales/components/SalesSnapCardList.vue";

import { Component, Vue, Emit } from "vue-property-decorator";
import BazzarApi from "@/api/bazaar.api";

import { countdown } from "@/utils/common.util";
import DestinyCartoonTimit from "@/views/timit-welfare/DestinyCartoonTimit.vue";
import { ISupplyBox } from "@/api/supply-package.api";
import SupplyPackageStateStore from "@/store/modules/supply-package-state.store";
import { getModule } from "vuex-module-decorators";
import BlindBoxItem from "@/views/welfare/components/BlindBoxItem.vue";
import BlindBoxApi, {
  IBlindBoxItem,
  IBlindBoxItemActive,
  SettingBlindBoxActivity,
} from "@/api/blind-box.api";
import { List, Dialog } from "vant";
import RulePop from "@/views/timit-welfare/components/RulePop.vue";
import RewordPop from "@/views/timit-welfare/components/RewordPop.vue";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    DestinyCartoonTimit,
    BlindBoxItem,
    RulePop,
    RewordPop,
    List,
    SalesSnapCardList,
  },
})
export default class TimitWelfare extends Vue {
  countdown: any = null;
  Timer: any = null;
  RulePopState = false;
  DeatilData: any = null;
  loading = false;
  finished = false;
  page = 0;
  page_size = 10;
  rewordPopState = false;
  close() {
    this.RulePopState = false;
  }
  confirm() {
    this.rewordPopState = false;
    window.scrollTo(0, 600);
  }
  mockData: IBlindBoxItemActive[] = [];
  checkoutNow = 1;
  get displayCheckoutA() {
    if (this.checkoutNow == 1) {
      return `display:block`;
    } else {
      return `display:none`;
    }
  }
  get displayCheckoutB() {
    if (this.checkoutNow == 2) {
      return `display:block`;
    } else {
      return `display:none`;
    }
  }
  get translateXLine() {
    if (this.checkoutNow == 1) {
      return `transform:translateX(0)`;
    } else {
      if (this.BgWidth < 350) {
        return `transform:translateX(${(this.BgWidth - 74) / 2}px)`;
      }
      return `transform:translateX(${(this.BgWidth - 24) / 2}px)`;
    }
  }
  checkout(num: number) {
    if (num == 1) {
      this.checkoutNow = 1;
    } else {
      this.checkoutNow = 2;
    }
  }
  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, blindBox } = this;
    if (page === 1) await this.handleActiveHiddenData();
    const rows = await BlindBoxApi.getBlindList(page, page_size);
    this.loading = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.blindBox =
      page > 1 ? [...blindBox, ...rows] : [...this.mockData, ...rows];
  }
  handleOpenBlindBox(): void {
    const { id } = this.DeatilData.blind_box_data[0];
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/blind-box/${id}`);
    }
    location.href = `${location.origin}/blind-box/${id}`;
  }
  async handleActiveHiddenDataItem(result: SettingBlindBoxActivity) {
    const activity = sessionStorage.getItem("activity") || "";
    if (!result.library.includes(activity)) return;
    const start = result?.start_time || 0;
    const timeout = result.finish_time;
    const time = Math.round(new Date().getTime() / 1000);
    if (start > 0 && start > time) return;
    if (time >= timeout) return;
    const row = await BlindBoxApi.getBlindListOne(result.box_id).catch(
      () => undefined
    );
    if (!row) return;
    if (row.box_status === 1) return;
    if (row.price) row.pay_type = true;
    if (!row.box_inventory) {
      Dialog.alert({
        message: result.empty_tip,
        confirmButtonText: result.empty_confirm,
      });
    }
    this.mockData.push(row);
  }

  async handleActiveHiddenData() {
    const result = await BlindBoxApi.getBlindSettingActivity();
    if (!result || !result.length) return;
    this.hiddenUserOrder();
    return Promise.all(
      result.map((item) => this.handleActiveHiddenDataItem(item))
    );
  }
  supplyBox: Array<ISupplyBox> = [];
  supplyPackageState = getModule(SupplyPackageStateStore);
  blindBox: IBlindBoxItem[] = [];
  openRule() {
    this.RulePopState = true;
  }
  @Emit()
  hiddenUserOrder() {
    return;
  }
  toBlindReword() {}
  getNewPeople: any = null;
  async created() {
    this.DeatilData = await BazzarApi.getRegisteredWelfareDetail();
    this.supplyBox = await this.supplyPackageState.getSupplyBoxList(
      this.DeatilData.blind_box_data[0].id
    );
    this.blindBox = await BlindBoxApi.getBlindList();
    const t =
      Math.ceil(new Date().getTime() / 1000) + this.DeatilData.countdown;
    this.Timer = setInterval(() => {
      const { day, hour, minute, second } = countdown(t);
      let hours = hour;
      if (Number(day) > 0) {
        hours = String(Number(hour) + 24 * Number(day));
      }
      this.countdown = `
                <div class="count-left-timit">
                <span>使用倒计时</span><span class="countdown-span-timit">${hours}</span><span class="countdown-span-timit-dot">:</span><span class="countdown-span-timit">${minute}</span><span class="countdown-span-timit-dot">:</span><span class="countdown-span-timit">${second}</span>
                </div>
        `;
    }, 1000);
  }
  get BgWidth() {
    const { clientWidth, offsetWidth } = document.body;
    if (clientWidth) {
      return clientWidth;
    }
    return 375;
  }
}
